import React, { useState, useEffect } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap, Tooltip  } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
function MapCard({ latitude, longitude, branchName, marker2 = {} }) {
    const [latitudePin, setLatitude] = useState(latitude);
    const [longitudePin, setLongtitue] = useState(longitude);
    useEffect(() => {
        // Update state when props change
        setLatitude(latitude);
        setLongtitue(longitude);
      }, [latitude, longitude]); 

      const isMarker2Valid =
      marker2?.lat !== undefined && marker2?.lng !== undefined;
    return (<>
        <div style={{ height: "400px", width: "100%" }}>
            <MapContainer  key={`${latitudePin}-${longitudePin}`} center={[latitudePin, longitudePin]} zoom={isMarker2Valid ? 20 :16} scrollWheelZoom={false} style={{ height: "100%", width: "100%" }} attributionControl={false}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="" 
                // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[latitudePin, longitudePin]}>
                    <Tooltip direction="right" permanent>
                        {branchName}
                    </Tooltip>
                    <Popup>
                        <span>{branchName}</span><br />
                        {latitudePin}, {longitudePin}
                    </Popup>
                </Marker>
                {isMarker2Valid && (
                    <Marker position={[marker2.lat, marker2.lng]}>
                    <Tooltip permanent>
                        Off
                    </Tooltip>
                    <Popup>
                        <span>Clock Off</span>
                        <br />
                        {marker2.lat}, {marker2.lng}
                    </Popup>
                    </Marker>
                )}
            </MapContainer>
        </div>
    </>);
}

export default MapCard;
