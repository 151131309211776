import { Routes, Route} from "react-router-dom";
import React, { useContext } from "react";
import { UserContext } from "./context/Auth.context";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Login from "./pages/Login";
import Designation from "./pages/master-files/Designation";
import Teams from "./pages/master-files/Teams";
import NewEmployee from "./pages/employees/NewEmployee";
import ProfileList from "./pages/employees/ProfileList";
import Profile from "./pages/employees/Profile";
import EditEmployee from "./pages/employees/EditEmployee";
import Payroll from "./pages/Payroll";
import Leave from "./pages/Leave";
import Attendance from "./pages/Attendance";
import Message from "./pages/Message";
import Dashboard from "./pages/Dashboard";
import Permission from "./pages/Permission";
import UserProfile from "./pages/UserProfile";
// import { PermissionContext } from "./context/PermissionCheck.context";
import Announcement from "./pages/Announcement";
import Policy from "./pages/Policy";
import PolicyCategory from "./pages/PolicyCategory";
import Organization from "./pages/Organizations/Organization";
import NewOrganization from "./pages/Organizations/NewOrganization";
import EditOrganization from "./pages/Organizations/EditOrganization";
import DocumentVerify from "./pages/DocumentVerify";
import RequestAccountDeletion from "./pages/RequestAccountDeletion";
import Branch from "./pages/master-files/Branch";
import ComplianceManagement from "./pages/compliance/ComplianceManagement";
import WasteManagement from "./pages/compliance/WasteManagement";
import AlcoholTraining from "./pages/compliance/AlcoholTraining";
import AgeManagement from "./pages/compliance/AgeManagement";
import TemperatureManagement from "./pages/compliance/TemperatureManagement";
import CCTVLogManagement from "./pages/compliance/CCTVLogManagement";
import HealthAndSafetyIncidentView from "./pages/compliance/HealthAndSafetyIncidentView";
import UserManagement from "./pages/UserManagement";
import AccessDenied from "./pages/AccessDenied";
import ProtectedRoute from "./components/ProtectedRoutes";
import ComplianceReport from "./pages/ComplianceReport";

export const AuthenticatedRoutes = () => {
  const { user, setUser } = useContext(UserContext);
  // const permission = useContext(PermissionContext);
  return (
    <>
      <Routes>
        <Route path="designations" element={<ProtectedRoute path="designations" element={Designation} />}/>
        <Route path="teams" element={<ProtectedRoute path="teams" element={Teams} />}/>
        <Route path="new-employee" element={<ProtectedRoute path="new-employee" element={NewEmployee} />}/>
        <Route path="existing-employees" element={<ProtectedRoute path="existing-employees" element={ProfileList} />}/>
        <Route path="employee-view/:id" element={<ProtectedRoute path="employee-view" element={Profile} />}/>
        <Route path="employee-edit/:id" element={<ProtectedRoute path="employee-edit" element={EditEmployee} />}/>
        <Route path="payroll" element={<ProtectedRoute path="payroll" element={Payroll} />}/>
        <Route path="leave" element={<ProtectedRoute path="leave" element={Leave} />}/>
        <Route path="attendance" element={<ProtectedRoute path="attendance" element={Attendance} />}/>
        <Route path="messages" element={<ProtectedRoute path="messages" element={Message} />}/>
        <Route path="dashboard" element={<ProtectedRoute path="dashboard" element={Dashboard} />}/>
        <Route path="permission" element={<ProtectedRoute path="permission" element={Permission} />}/>
        <Route path="account" element={<ProtectedRoute path="account" element={UserProfile} />}/>
        <Route path="announcements" element={<ProtectedRoute path="announcements" element={Announcement} />}/>
          <Route path="policies" element={<ProtectedRoute path="policies" element={Policy} />}/>
          <Route path="/policies/:id" element={<PolicyCategory />} />
          <Route path="organizations" element={<ProtectedRoute path="organizations" element={Organization} />}/>
          <Route path="new-organization" element={<ProtectedRoute path="new-organization" element={NewOrganization} />}/>
          <Route path="organization-edit/:id" element={<ProtectedRoute path="organization-edit" element={EditOrganization} />}/>
          <Route path="document-verification" element={<ProtectedRoute path="document-verification" element={DocumentVerify} />}/>
          <Route path="branches" element={<ProtectedRoute path="branches" element={Branch} />}/>
          <Route path="compliance" element={<ProtectedRoute path="compliance" element={ComplianceManagement} />}/>
          <Route path="temperature-log" element={<ProtectedRoute path="temperature-log" element={TemperatureManagement} />}/>
          <Route path="age-verification" element={<ProtectedRoute path="age-verification" element={AgeManagement} />}/>
          <Route path="waste-management" element={<ProtectedRoute path="waste-management" element={WasteManagement} />}/>
          <Route path="alcohol-training" element={<ProtectedRoute path="alcohol-training" element={AlcoholTraining} />}/>
          <Route path="cctv-log-management" element={<ProtectedRoute path="cctv-log-management" element={CCTVLogManagement} />}/>
          <Route path="health-and-safety" element={<ProtectedRoute path="health-and-safety" element={HealthAndSafetyIncidentView} />}/>
          <Route path="user-management" element={<ProtectedRoute path="user-management" element={UserManagement} />}/>
          <Route path="compliance-reports" element={<ProtectedRoute path="compliance-reports" element={ComplianceReport} />}/>
          <Route path={"/unauthorized"} element={<AccessDenied />} />
      </Routes>
    </>
  );
};

export const UnauthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/password/reset/:token/:mail" element={<ResetPassword />} />
      <Route path="/delete-user" element={<RequestAccountDeletion />} />
    </Routes>
  );
};


export const UnAuthorizedRoutes = () => {

  return (
    <>
      <h1 class="mt-4">401 - Access Denied!</h1>
      <div class="w-75 error-text mx-auto mt-4">
        <p>Sorry You don't have permission to this page.</p>
      </div>
    </>
  );
};
