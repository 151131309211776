import React, { useState, useEffect, useRef  } from "react";

import { DatePicker } from "antd";
import moment from "moment";
import { getActiveCompanyBranches } from "../services/BranchAPIServices";
import { getComplianceAccessSections, getFreezersList, getPublishedQuizList, getReportData } from "../services/ComplianceAPIServices";
import { getEmployees } from "../services/EmployeeAPIServices";
import DropDown from "../components/DropDown";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";

function ComplianceReport() {
  const { RangePicker } = DatePicker;
  const csvLinkRef = useRef(null);
  const [complianceTypes, setComplianceTypes] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [quizList, setQuizList] = useState([]);
  const [freezerList, setFreezerList] = useState([]);
  const [wasteTypeList, setWasteTypeList] = useState([{ 'id': '', 'name': 'All Types' },{ 'id': '1', 'name': 'Disponse to Bin' },{ 'id': '0', 'name': 'Return to supplier' }]);
  const [quizReportTypeList, setQuizReportTypeList] = useState([{ 'id': '1', 'name': 'Lastest Attempt' },{ 'id': '0', 'name': 'All Attempts' }]);
  const [compliance_id, setComplianceId] = useState("");
  const [branch_id, setBranchId] = useState("");
  const [freezer_id, setFreezerId] = useState("");
  const [quiz_id, setQuizId] = useState("");
  const [waste_type, setWasteType] = useState("");
  const [employee_id, setEmployeeId] = useState("");
  const [quiz_report_type, setQuizReportType] = useState("1");
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [reportDataset, setReportDataset] = useState([]);
  const [reportFileName, setReportFileName] = useState("");
  const [allowDownload, setAllowDownload] = useState(false);

  // dynamic visible manage
  const [branchVisible, setBranchVisible] = useState(false);
  const [employeeVisible, setEmployeeVisible] = useState(false);
  const [dateRangeVisible, setDateRangeVisible] = useState(false);
  const [quizVisible, setQuizVisible] = useState(false);
  const [quizTypeVisible, setQuizTypeVisible] = useState(false);
  const [freezerVisible, setFreezerVisible] = useState(false);
  const [wasteTypeVisible, setWasteTypeVisible] = useState(false);
  const [reportButtonControl, setReportButtonControl] = useState(false);
  useEffect(() => {
    loadData();
  }, []);


  const loadData = () => {

    getComplianceAccessSections().then((response) => {
      setComplianceTypes(response)
    }).catch((error) => { console.error(error) });

    getActiveCompanyBranches().then((response) => {
      let newRow = { 'id': '0', 'name': 'All Branches' };
      response.push(newRow);
      setBranchesList(response.reverse())
    }).catch((error) => { console.error(error) });

    getEmployees().then((response) => {
      let updatedList = dropDownListTransform(response,'full_name')
      let newRow = { 'id': '0', 'name': 'All Employee' };
      updatedList.push(newRow);
      setEmployeeList(updatedList.reverse());
    });

    getPublishedQuizList().then((response) => {
      let updatedList = dropDownListTransform(response,'title')
      let newRow = { 'id': '0', 'name': 'All Quiz' };
      updatedList.push(newRow);
      setQuizList(updatedList.reverse())
    }).catch((error) => { console.error(error) });

    getFreezersList().then((response) => {
      let updatedList = dropDownListTransform(response,'freezer_name')
      let newRow = { 'id': '0', 'name': 'All Freezers' };
      updatedList.push(newRow);
      setFreezerList(updatedList.reverse())
    }).catch((error) => { console.error(error) });
  }

  const dropDownListTransform = (list,key) =>{
    return list.map((record) => ({
      ...record,
      name: record[key], 
    }));
  }

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setFilterTo(dateStrings[1]);
      setFilterFrom(dateStrings[0]);
    } else {
      setFilterTo("");
      setFilterFrom("");
    }
  };

  const complianceTypeChange = (value) =>{
    const selectedMainType = complianceTypes.find((team) => team.id == value);
    setComplianceId(value);

    if (selectedMainType) {
      if (selectedMainType.web_route == 'age-verification') {
        setBranchVisible(true);
        setDateRangeVisible(true);
        setEmployeeVisible(true);
        setQuizTypeVisible(false);
        setQuizVisible(false);
        setFreezerVisible(false);
        setWasteTypeVisible(false);
      } else if (selectedMainType.web_route == 'temperature-log') {
        setBranchVisible(true);
        setDateRangeVisible(true);
        setEmployeeVisible(true);
        setQuizTypeVisible(false);
        setQuizVisible(false);
        setFreezerVisible(true);
        setWasteTypeVisible(false);
      } else if (selectedMainType.web_route == 'waste-management') {
        setBranchVisible(true);
        setDateRangeVisible(true);
        setEmployeeVisible(true);
        setQuizTypeVisible(false);
        setQuizVisible(false);
        setFreezerVisible(false);
        setWasteTypeVisible(true);
      } else if(selectedMainType.web_route == 'cctv-log-management'){
        setBranchVisible(true);
        setDateRangeVisible(true);
        setEmployeeVisible(false);
        setQuizTypeVisible(false);
        setQuizVisible(false);
        setFreezerVisible(false);
        setWasteTypeVisible(false);
      } else if(selectedMainType.web_route == 'alcohol-training'){
        setBranchVisible(false);
        setDateRangeVisible(false);
        setEmployeeVisible(true);
        setQuizTypeVisible(true);
        setQuizVisible(true);
        setFreezerVisible(false);
        setWasteTypeVisible(false);
      }else if(selectedMainType.web_route == 'health-and-safety'){
        setBranchVisible(true);
        setDateRangeVisible(true);
        setEmployeeVisible(true);
        setQuizTypeVisible(false);
        setQuizVisible(false);
        setFreezerVisible(false);
        setWasteTypeVisible(false);
      }

    } else {
      setBranchVisible(false);
    }

  }

  const handleGetReportData = () =>{
    const data = {compliance_id,branch_id,filterFrom,filterTo,employee_id,freezer_id,waste_type,quiz_id,quiz_report_type}
    setReportButtonControl(true);
    getReportData(data).then((response) => {
     
      if(response.data.status_code == 1){
        setReportFileName(response.data.data.report+".csv")
        setReportDataset(response.data.data.list)
        setAllowDownload(true);
        if(response.data.data.list.length > 0){
          setTimeout(() => {
            csvLinkRef.current.link.click(); 
           }, 0);
        }else{
          Swal.fire({
            icon: "warning",
            title: "Records were not found for the report.",
            showConfirmButton: false,
            timer: 1500
          });
        }
      
      }else{
        setAllowDownload(false); 
        Swal.fire("Warning", " " + response.data.message, "warning");
      }
      setReportButtonControl(false);
    }).catch((error) => {  setAllowDownload(false);  console.error(error) });
  }

  const generatePDF = () => {
    
    const data = {compliance_id,branch_id,filterFrom,filterTo,employee_id,freezer_id,waste_type,quiz_id,quiz_report_type}
    setReportButtonControl(true);
    getReportData(data).then((response) => {
     
      if(response.data.status_code == 1){
        if(response.data.data.list.length > 0){
          let doc;
            if(compliance_id == 4){
              doc = new jsPDF('landscape', 'mm', 'a4'); 
            }else{
              doc = new jsPDF();
            }
           
            const title = response.data.data.report;
            const subtitle = `Generated on ${new Date().toLocaleDateString()} at ${new Date().toLocaleTimeString()}`;
            const currentTime = new Date().toLocaleTimeString();

            const pdfWidth = doc.internal.pageSize.getWidth();
            const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const subtitleWidth = doc.getStringUnitWidth(subtitle) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const titleX = (pdfWidth - titleWidth) / 2; 
            doc.setFontSize(16);
            doc.text(title, titleX, 20);
            doc.setFontSize(12);
            doc.text(subtitle, (pdfWidth - subtitleWidth) / 2, 30);

            const headers = response.data.data.list.length > 0 
            ? Object.keys(response.data.data.list[0]).map((key) =>
                key
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())
              )
            : [];
            const tableData = response.data.data.list.map((item) => Object.values(item));

            doc.autoTable({
              startY: 32,
              head: [headers],
              body: tableData,
              theme: "grid",
              margin: { top: 35, left: 8, right: 8 },
              headStyles: {
                fillColor: '#79829A', 
                textColor: [255, 255, 255], 
                fontSize: 11, 
                // halign: "center", 
              },
              bodyStyles: {
                fontSize: 10, 
              },
              didDrawPage: (data) => {
              
                const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
                if (currentPage != 1) {
                  
                    data.settings.margin.top = 12; // Reduce top margin for subsequent pages
                    doc.setPage(currentPage);
                }
                
                const pageCount = doc.internal.getNumberOfPages();
                const pageSize = doc.internal.pageSize;
                const pageWidth = pageSize.getWidth();
                const pageHeight = pageSize.getHeight();

                doc.setFontSize(10);
                doc.setTextColor(100);

                const footerText = `Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${pageCount}`;
                doc.text(footerText, pageWidth / 2, pageHeight - 10, { align: "center" });

                
                const footerTime = `${currentTime}`;
                doc.text(footerTime, pageWidth - 15, pageHeight - 10, { align: "right" });
              },
            });

            doc.save(response.data.data.report+".pdf");
          }else{
            Swal.fire({
              icon: "warning",
              title: "Records were not found for the report.",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }else{
            Swal.fire("Warning", " " + response.data.message, "warning");
        }
        setReportButtonControl(false);
      }).catch((error) => {  console.error(error) });
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3> Compliance Reports </h3>
        </div>
      </div>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3" >
                      <DropDown
                        label={"Compliance Type"}
                        value={compliance_id}
                        list={complianceTypes}
                        required={true}
                        onchange={(e) => complianceTypeChange(e.target.value)}
                      ></DropDown>
                    </div>
                    <div className="col-md-3" style={{ display: branchVisible ? "block" : "none" }}>
                      <DropDown
                        label={"Branch"}
                        value={branch_id}
                        list={branchesList}
                        required={false}
                        initial={false}
                        onchange={(e) => setBranchId(e.target.value)}
                      ></DropDown>
                    </div>
                    <div className="col-md-4"  style={{ display: dateRangeVisible ? "block" : "none" }}>
                      <label for="users-list-role">Date Period <span style={{ color: "red" }}>*</span></label>
                      <RangePicker
                        className="form-control"
                        ranges={{
                          Today: [moment(), moment()],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                        }}
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-md-3"  style={{ display: employeeVisible ? "block" : "none" }}>
                      <DropDown
                        label={"Employee"}
                        value={employee_id}
                        list={employeeList}
                        required={false}
                        initial={false}
                        onchange={(e) => setEmployeeId(e.target.value)}
                      ></DropDown>
                    </div>
                    <div className="col-md-3"  style={{ display: freezerVisible ? "block" : "none" }}>
                      <DropDown
                        label={"Freezer"}
                        value={freezer_id}
                        list={freezerList}
                        required={false}
                        initial={false}
                        onchange={(e) => setFreezerId(e.target.value)}
                      ></DropDown>
                    </div>

                    <div className="col-md-3"  style={{ display: quizVisible ? "block" : "none" }}>
                      <DropDown
                        label={"Quiz"}
                        value={quiz_id}
                        list={quizList}
                        required={false}
                        initial={false}
                        onchange={(e) => setQuizId(e.target.value)}
                      ></DropDown>
                    </div>
                    <div className="col-md-3"  style={{ display: wasteTypeVisible ? "block" : "none" }}>
                      <DropDown
                        label={"Waste Type"}
                        value={waste_type}
                        list={wasteTypeList}
                        required={false}
                        initial={false}
                        onchange={(e) => setWasteType(e.target.value)}
                      ></DropDown>
                    </div>
                    <div className="col-md-3"  style={{ display: quizTypeVisible ? "block" : "none" }}>
                      <DropDown
                        label={"Quiz Report Type"}
                        value={quiz_report_type}
                        list={quizReportTypeList}
                        required={false}
                        initial={false}
                        onchange={(e) => setQuizReportType(e.target.value)}
                      ></DropDown>
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex  justify-content-end">
                <button
                  onClick={generatePDF}
                  className="btn btn-secondary glow mb-0 mr-2 float-right" 
                  disabled={reportButtonControl}
                >
                  Generate PDF Report <i className="ft-download"></i>
                </button>
                <button 
                  className="btn btn-primary glow mb-0 float-right" 
                  onClick={handleGetReportData}
                  disabled={reportButtonControl}
                >
                  Generate CSV Report <i className="ft-download"></i>
                </button>
                <CSVLink
                  filename={reportFileName}
                  data={reportDataset}
                  className="d-none" 
                  ref={csvLinkRef}
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ComplianceReport;
